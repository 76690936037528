import "./App.css";
import edutreezLogo from "./assets/edutreez.png";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="content-wrapper">
        <div className="logo-container">
          <img src={edutreezLogo} alt="edutreez-logo" width={600} height={600}></img>
        </div>
        <div className="descriptionContainer">
          <p className="description">
            Your ultimate one step solution to find teachers.
          </p>
          <p className="comingSoonText">Coming Soon!!</p>
        </div>
      </div>
    </div>
  );
}

export default App;
